import React, {
  useState,
  createContext,
  FC,
  ReactNode,
  useEffect,
} from 'react';
import { IProject, IProjectContext } from '../utils/types';
import { createProject, getAllProjects, getFourProjects, getProject, upvoteProject } from '../apis/projects';
import { getAllSponsoredListings } from '../apis/sponsoredListing';


const defaultSearchContext: IProjectContext = {
  projects: [],
  setProjects: () => {},
  project: {
    _id: '',
    name: '',
    coverImg: '',
    logo: '',
    tldr: '',
    upvotes: 0,
    tags: [{ name: '' }],
    links: {
      website: '',
      github: '',
      facebook: '',
      instagram: '',
      twitter: '',
      tiktok: '',
      telegram: '',
      youtube: '',
      reddit: '',
      discord: '',
      linkedin: '',
      appstore: '',
      playstore: ''
    }
  },
  setProject: () => {},
  createNewProject: () => {},
  sponsoredListings: [],
  setSponsoredListings: () => {},
  handleGetOneProject: async () => { return; },
  resetStates: () => {},
  handleUpvoteProject: async () => { return; },
};
export const ProjectContext = createContext<IProjectContext>(
  defaultSearchContext
);

interface Props {
  children: ReactNode;
}

const ProjectContextContainer: FC<Props> = (props) => {
  const [projects, setProjects] = useState<any[]>([]);
  const [project, setProject] = useState<IProject>(defaultSearchContext.project);
  const [sponsoredListings, setSponsoredListings] = useState<any[]>();

  const handleGetProjects = async () => {
    try {
      const allProjects = await getAllProjects();
      setProjects(allProjects);
    } catch(error) {
      console.error(error);
    }
  }

  const handleGetSponsoredListings = async () => {
    try {
      const allProjectListings = await getAllSponsoredListings();
      setSponsoredListings(allProjectListings);
    } catch(error) {
      console.error(error);
    }
  }

  const createNewProject = async(project_data: any) => {
    try {
      const test = await createProject(project_data);
    } catch(error) {
      console.error(error);
    }
  }

  const handleGetOneProject = async(project_id: string): Promise<void> => {
    try {
      const res = await getProject(project_id);
      console.log("---- ", project_id)
      setProject(res);
    } catch(error) {
      console.error(error);
    }
  }

  const resetStates = () => {
    setProject(defaultSearchContext.project);
  }

  const handleUpvoteProject = (project_id: string) => upvoteProject(project_id);

  useEffect(() => {
    handleGetProjects();
    handleGetSponsoredListings();
  }, [window.location])

  return (
    <ProjectContext.Provider
      value={{
        projects,
        setProjects,
        project,
        setProject,
        createNewProject,
        sponsoredListings,
        setSponsoredListings,
        handleGetOneProject,
        resetStates,
        handleUpvoteProject,
      }}
    >
      {props.children}
    </ProjectContext.Provider>
  );
};

export default ProjectContextContainer;

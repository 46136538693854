import axios from "axios"
import { baseUrl } from "./baseUrl";


const TAGS_KEY = "tags";
const CACHE_EXPIRY = 300000; // 10s minutes

const storeDataInLocalStorage = (key: string, data: any) => {
  const cache = {
    data,
    timestamp: Date.now(),
  };
  localStorage.setItem(key, JSON.stringify(cache));
};

const getDataFromLocalStorage = (key: string) => {
  const cache = localStorage.getItem(key);
  if (!cache) return null;

  const { data, timestamp } = JSON.parse(cache);
  if (Date.now() - timestamp < CACHE_EXPIRY) {
    return data;
  }

  localStorage.removeItem(key);
  return null;
};


export const getAllTags = async() => {

  const cachedData = getDataFromLocalStorage(TAGS_KEY);
  if (cachedData) return cachedData;

  const res = await axios.get(`${baseUrl}tag`);
  storeDataInLocalStorage(TAGS_KEY, res.data);
  return res.data;
}

// export const getAllTags = async () => {
//   const response = await fetch('/projectsDb.json');
//   const data = await response.json();
//   return data.tags;
// };
exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-project-index-tsx": () => import("./../../../src/pages/add-project/index.tsx" /* webpackChunkName: "component---src-pages-add-project-index-tsx" */),
  "component---src-pages-add-project-social-input-tsx": () => import("./../../../src/pages/add-project/SocialInput.tsx" /* webpackChunkName: "component---src-pages-add-project-social-input-tsx" */),
  "component---src-pages-admined-index-tsx": () => import("./../../../src/pages/admined/index.tsx" /* webpackChunkName: "component---src-pages-admined-index-tsx" */),
  "component---src-pages-auth-index-jsx": () => import("./../../../src/pages/auth/index.jsx" /* webpackChunkName: "component---src-pages-auth-index-jsx" */),
  "component---src-pages-category-jsx": () => import("./../../../src/pages/Category.jsx" /* webpackChunkName: "component---src-pages-category-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-profile-details-jsx": () => import("./../../../src/pages/profile-details.jsx" /* webpackChunkName: "component---src-pages-profile-details-jsx" */),
  "component---src-pages-project-details-category-name-project-name-tsx": () => import("./../../../src/pages/project-details/[categoryName]/[projectName].tsx" /* webpackChunkName: "component---src-pages-project-details-category-name-project-name-tsx" */),
  "component---src-pages-search-results-index-jsx": () => import("./../../../src/pages/search-results/index.jsx" /* webpackChunkName: "component---src-pages-search-results-index-jsx" */),
  "component---src-pages-search-results-search-card-jsx": () => import("./../../../src/pages/search-results/SearchCard.jsx" /* webpackChunkName: "component---src-pages-search-results-search-card-jsx" */),
  "component---src-pages-search-results-suggestions-jsx": () => import("./../../../src/pages/search-results/Suggestions.jsx" /* webpackChunkName: "component---src-pages-search-results-suggestions-jsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}


import axios from "axios"
import { baseUrl } from "./baseUrl";



const CATEGORY_KEY = "coreCategory";
const CACHE_EXPIRY = 300000; // 10s minutes

const storeDataInLocalStorage = (key: string, data: any) => {
  const cache = {
    data,
    timestamp: Date.now(),
  };
  localStorage.setItem(key, JSON.stringify(cache));
};

const getDataFromLocalStorage = (key: string) => {
  const cache = localStorage.getItem(key);
  if (!cache) return null;

  const { data, timestamp } = JSON.parse(cache);
  if (Date.now() - timestamp < CACHE_EXPIRY) {
    return data;
  }

  localStorage.removeItem(key);
  return null;
};


export const getAllCategories = async() => {
  const cachedData = getDataFromLocalStorage(CATEGORY_KEY);
  if (cachedData) return cachedData;

  const res = await axios.get(`${baseUrl}core-category`);
  storeDataInLocalStorage(CATEGORY_KEY, res.data);
  return res.data;
}

export const addTags = async(data) => {
  await axios.post(`${baseUrl}core-category/add-tags`, data);
  const res = await axios.get(`${baseUrl}core-category`);
  storeDataInLocalStorage(CATEGORY_KEY, res.data);
}

export const addCategory = async(data) => {
  await axios.post(`${baseUrl}core-category`, data);
  const res = await axios.get(`${baseUrl}core-category`);
  storeDataInLocalStorage(CATEGORY_KEY, res.data);
  return res.data;
}
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React from 'react';
import ProjectContextContainer from './src/context/ProjectContext';
import SearchContextContainer from './src/context/SearchContext';

export const wrapRootElement = ({ element }) => (
  <ProjectContextContainer>
    <SearchContextContainer>
      {element}
    </SearchContextContainer>
  </ProjectContextContainer>
);

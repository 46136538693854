import axios from "axios";
import { baseUrl } from "./baseUrl";

const PROJECTS_KEY = "projects";
const FOUR_PROJECTS_KEY = "fourProjects";
const PROJECT_KEY_PREFIX = "project_";
const CACHE_EXPIRY = 300000; // 10s minutes

const storeDataInLocalStorage = (key: string, data: any) => {
  const cache = {
    data,
    timestamp: Date.now(),
  };
  localStorage.setItem(key, JSON.stringify(cache));
};

const getDataFromLocalStorage = (key: string) => {
  const cache = localStorage.getItem(key);
  if (!cache) return null;

  const { data, timestamp } = JSON.parse(cache);
  if (Date.now() - timestamp < CACHE_EXPIRY) {
    return data;
  }

  localStorage.removeItem(key);
  return null;
};

export const getAllProjects = async () => {
  const cachedData = getDataFromLocalStorage(PROJECTS_KEY);
  if (cachedData) return cachedData;

  const res = await axios.get(`${baseUrl}project`);
  storeDataInLocalStorage(PROJECTS_KEY, res.data);
  return res.data;
};

export const getFourProjects = async () => {
  const cachedData = getDataFromLocalStorage(FOUR_PROJECTS_KEY);
  if (cachedData) return cachedData;

  const res = await axios.get(`${baseUrl}project/four`);
  storeDataInLocalStorage(FOUR_PROJECTS_KEY, res.data);
  return res.data;
};

export const getProject = async (project_id: string) => {
  const cachedData = getDataFromLocalStorage(PROJECTS_KEY);
  if (cachedData) {
    return cachedData.filter((data) => data.name == project_id)[0];
  }
  getAllProjects().then(() => {
    const cachedData = getDataFromLocalStorage(PROJECTS_KEY);
    if (cachedData) {
      return cachedData.filter((data) => data.name == project_id)[0];
    }
  })

  // console.log("HERE ---- ", test)
  // const cachedData = getDataFromLocalStorage(`${PROJECT_KEY_PREFIX}${project_id}`);
  // if (cachedData) return cachedData;

  // const res = await axios.get(`${baseUrl}project/${project_id}`);
  // storeDataInLocalStorage(`${PROJECT_KEY_PREFIX}${project_id}`, res.data);
  // return res.data;
};

export const createProject = async (project_data: any) => {
  const res = await axios.post(`${baseUrl}project`, project_data);
  const cachedData = getDataFromLocalStorage(PROJECTS_KEY);
  cachedData.push(project_data)
  storeDataInLocalStorage(PROJECTS_KEY, cachedData);
  console.log("i------------------------ ", cachedData)
  await refreshProjectsCache(); // Update local storage after creating a project
  return res.data;
};

// export const getProjectsBySearch = async (searchValue: string) => {
//   try {
//     const response = await axios.post(`${baseUrl}project/search`, { searchValue });
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching projects:", error);
//     throw error;
//   }
// };
export const getProjectsBySearch = async (searchValue: string) => {
  try {
    // Fetch the projects from localStorage
    const projects = JSON.parse(localStorage.getItem('projects') || '[]');

    // Ensure projects is an array
    if (!Array.isArray(projects.data)) {
      console.error("Projects data in localStorage is not an array:", projects);
      return [];
    }

    // Filter projects based on search value
    const matchingProjects = projects.data.filter((project: any) => {
      const matchesName = project.name?.toLowerCase().includes(searchValue.toLowerCase()) || false;
      const matchesOverview = project.overview?.toLowerCase().includes(searchValue.toLowerCase()) || false;
      const matchesCategory = project.coreCategories?.some((category: any) =>
        category.name?.toLowerCase().includes(searchValue.toLowerCase())
      ) || false;

      console.log("MATCH NAME - ", matchesName);
      console.log("OVERVIEW - ", matchesOverview);
      console.log("CATE ", matchesCategory);

      return matchesName || matchesOverview || matchesCategory;
    });

    console.log("Matching Projects: ", matchingProjects);

    return matchingProjects;
  } catch (error) {
    console.error("Error fetching projects from localStorage:", error);
    throw error;
  }
};



export const upvoteProject = async (project_id: string) => {
  try {
    const response = await axios.post(`${baseUrl}project/upvote`, { project_id });
    await refreshProjectsCache(); // Update local storage after upvoting a project
    return response.data;
  } catch (error) {
    console.error("Error upvoting", error);
    throw error;
  }
};

const refreshProjectsCache = async () => {
  try {
    const projects = await axios.get(`${baseUrl}project`);
    storeDataInLocalStorage(PROJECTS_KEY, projects.data);

    const fourProjects = await axios.get(`${baseUrl}project/four`);
    storeDataInLocalStorage(FOUR_PROJECTS_KEY, fourProjects.data);

    projects.data.forEach((project: any) => {
      storeDataInLocalStorage(`${PROJECT_KEY_PREFIX}${project.id}`, project);
    });
  } catch (error) {
    console.error("Error refreshing projects cache:", error);
  }
};

// Periodically refresh cache in the background
setInterval(refreshProjectsCache, CACHE_EXPIRY);
